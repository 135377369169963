import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Flex, Box } from "reflexbox/styled-components"
import styled from "styled-components"
import GreySection from "../components/Section/GreySection"
import GreenH2 from "../components/Typography/H2/GreenH2"
import WrapperContainers from "../components/WrapperContainer"
import P from "../components/Typography/P/P"
import StyledDesignDevBg from "../containers/DesignDevelopment/DesignDevHero"

const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
`
const H3 = styled.h3`
  font-size: calc(0.8rem + 0.8vw);
  text-align: center;
  color: ${({ theme }) => theme.green};
  font-family: "OFL Sorts Mill Goudy";
  text-transform: capitalize;
`
const Card = styled.div`
  border: 1px solid #29d9d5;
  border-radius: 5px;
  background: ${({ theme }) => theme.cardBg};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`
const DevImg = styled.img`
  padding-top: 20px;
  width: 80%;
  height: auto;
  display: block;
  margin: auto;
`

const ImgStreamed = styled.img`
  width: 100%;
  height: auto;
`

const GreenH2center = styled.h2`
  font-size: calc(0.8rem + 1.4vw);
  color: ${({ theme }) => theme.green};
  font-family: "OFL Sorts Mill Goudy";
  text-transform: capitalize;
  text-align: center;
`
const Pcenter = styled(P)`
  text-align: center;
`
const GreenH2resized = styled(GreenH2)`
  font-size: calc(0.8rem + 1.4vw);
  color: ${({ theme }) => theme.green};
`
const LastPad = styled.div`
  padding-top: 8vh;
  @media (max-width: 768px) {
    padding-top: 0;
  }
`
const DesignDev = () => (
  <Layout>
    <Seo title="jewelry product development Advance Manufacturers" />
    <StyledDesignDevBg />

    <Section>
      <Flex flexWrap="wrap">
        <Box width={[1, 1]} p={[2, 2, 3]}>
          <GreenH2center>
            STREAMLINED PROCESS FROM DESIGN TO FINISHED PROTOTYPE WITHIN 2 WEEKS
          </GreenH2center>
          <Pcenter>
            We offer design and OEM/ODM services to bring your creative visions
            to life. Our design and development team work closely with you to
            ensure that new sketches can be delivered to you within 24 hours of
            your request.
          </Pcenter>
        </Box>{" "}
        <Box width={[1, 1 / 4]} p={[2, 2, 5]}>
          <Card>
            <DevImg
              src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632654417/DesignDevelopment/green_vector-1-01_qf5x7k.svg"
              alt="jewelry product development "
            />{" "}
            <H3>Your vision</H3>
          </Card>
        </Box>
        <Box width={[1, 1 / 4]} p={[2, 2, 5]}>
          {" "}
          <Card>
            <DevImg
              src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632654417/DesignDevelopment/green_vector-3-01_yqieg2.svg"
              alt="jewelry design service development "
            />

            <H3>our experience</H3>
          </Card>
        </Box>
        <Box width={[1, 1 / 4]} p={[2, 2, 5]}>
          {" "}
          <Card>
            <DevImg
              src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632654417/DesignDevelopment/green_vector-4-01_pcffx0.svg"
              alt="jewelry production "
            />
            <H3>our quality</H3>
          </Card>
        </Box>
        <Box width={[1, 1 / 4]} p={[2, 2, 5]}>
          {" "}
          <Card>
            <DevImg
              src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632654417/DesignDevelopment/green_vector-2-01_huqaru.svg"
              alt="jewelry branding"
            />
            <H3> your creation</H3>
          </Card>
        </Box>
      </Flex>
    </Section>

    <GreySection>
      {" "}
      <Section>
        <Flex flexWrap="wrap">
          <Box width={[1, 1]} p={[2, 2, 3]} order={[1, 1, 2]}>
            <GreenH2resized>Pre-Design</GreenH2resized>
            <P>
              Before we begin the design process, customers can provide a simple
              brief with a concept you have in mind. It can be as simple as a
              few pictures of designs you like to show the look you are going
              for or a complete product brief that includes photos, weight and
              stone requirements, and price expectations. The more details
              provided the better. We then take your brief and come up with a
              design mood board prepared with color schemes &amp; stone
              selections which are used to prepare preliminary sketches for you.
            </P>
          </Box>{" "}
        </Flex>
      </Section>
    </GreySection>
    <Section>
      <Flex flexWrap="wrap">
        <Box width={[1, 1 / 2]} p={[2, 2, 0]} order={[3, 3, 4]}>
          <ImgStreamed
            src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632656169/DesignDevelopment/img_6593_lej9ff.jpg"
            alt=""
          />
        </Box>{" "}
        <Box width={[1, 1 / 2]} p={[2, 2, 3]} order={[4, 4, 3]}>
          <GreenH2resized>Design</GreenH2resized>
          <P>
            Once the aesthetic of the jewelry is finalized, our designers get to
            the real work. Sketches are done by hand with aid of design
            illustrator programs by our team of professional jewelry designers
            with years of experience. The sketches are then sent to you and if
            revisions are required then the designers will submit their
            revisions to you in a timely process.
          </P>
        </Box>
      </Flex>
    </Section>
    <GreySection>
      {" "}
      <Section>
        <Flex flexWrap="wrap">
          <Box width={[1, 1 / 2]} p={[2, 2, 3]} order={[5, 5, 6]}>
            <GreenH2resized>CAD/CAM</GreenH2resized>
            <P>
              Upon confirmation of the sketches, the CAD (Computer Aided Design)
              designers use specialized software to create 3D digital models of
              your jewelry. Our CAD designers are specially trained in creating
              the 3D files while maintaining an equal balance of design
              aesthetic and technical CAD skills. They work directly with the
              design team to creatively interpret 2D drawings into 3D CAD models
              whilst making modifications required for production along the way.
              In this process we are able to create photo-realistic CAD renders
              where we are able to view the jewelry from every angle and send to
              you, the client to check.
            </P>
          </Box>
          <Box width={[1, 1 / 2]} p={[2, 2, 3]} order={[6, 6, 5]}>
            <ImgStreamed
              src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1633330559/DesignDevelopment/cad-cam_lggk1j.jpg"
              alt=""
            />
          </Box>{" "}
        </Flex>
      </Section>
    </GreySection>
    <Section>
      <Flex flexWrap="wrap">
        <Box width={[1, 1 / 2]} p={[2, 2, 3]} order={[7, 7, 8]}>
          <ImgStreamed
            src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,c_scale,w_1920/v1633584149/DesignDevelopment/Photo_3_ekyvrp.jpg"
            alt=""
          />
        </Box>{" "}
        <Box width={[1, 1 / 2]} p={[2, 2, 3]} order={[8, 8, 7]}>
          <GreenH2resized>3D CAD Printing</GreenH2resized>
          <P>
            Once the CAD renders are checked by the sampling production manager
            and then confirmed by the customer, the 3D files are used to print
            the master models in castable resin. The castable resins are then
            used to cast a silver mould which is finished by our in-house
            modelmakers. Our CAD printers run a few times a day ensuring that
            all customers get their sample prototypes quickly.
          </P>
        </Box>
      </Flex>
    </Section>
    <GreySection>
      {" "}
      <Section>
        <Flex flexWrap="wrap">
          <Box width={[1, 1 / 2]} p={[2, 2, 3]} order={[10, 10, 9]}>
            <ImgStreamed
              src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632656169/DesignDevelopment/_mg_6810_wbfieh.jpg"
              alt=""
            />
          </Box>
          <Box width={[1, 1 / 2]} p={[2, 2, 3]} order={[9, 9, 10]}>
            <GreenH2resized>Silver Master Model Finishing</GreenH2resized>
            <P>
              The silver master models are then buffed and perfected by our
              modelmakers and stone setters who ensure that the master model is
              ready for mass production. In the past, all of our master models
              were made from hand by this same team of modelmakers. With all the
              advances in CAD/CAM technologies in the past decade, the majority
              of our master models are now created in CAD however, for intricate
              and delicate designs, our team of goldsmiths &amp; model makers
              remain the heart of our product development team.
            </P>
          </Box>{" "}
        </Flex>
      </Section>
    </GreySection>
    <Section>
      <Flex flexWrap="wrap">
        <Box width={[1, 1 / 2]} p={[2, 2, 3]} order={[11]}>
          <LastPad />
          <GreenH2resized>Your Finished Jewelry Design</GreenH2resized>
          <P>
            The samples are then produced in a dedicated sampling department and
            then shipped off to you for approval for bulk production. The
            development process can be challenging but with our well experienced
            team, you can count on a quick and hassle free communication and
            execution by our product development team.
          </P>
        </Box>
        <Box width={[1, 1 / 2]} p={[2, 2, 3]} order={[12]}>
          <ImgStreamed
            src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1633252355/DesignDevelopment/azp5327_bgwgfk.jpg"
            alt=""
          />
        </Box>{" "}
      </Flex>
    </Section>
  </Layout>
)

export default DesignDev
